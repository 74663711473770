import React from "react";
import "./project.css";
import IMG1 from "../../assets/letsflix1.png";
import IMG2 from "../../assets/ai-image1.png";
import IMG3 from "../../assets/calculator.png";
import IMG4 from "../../assets/portfolio1.png";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "MERN Stack Movie App",
    // github: "https://github.com/Ade-mir/MERN-social-media-platform",
    demo: "https://letsflix.vercel.app/",
    
  },
  {
    id: 2,
    image: IMG2,
    title: "MERN stack | AI Image generator",
    demo: "https://ai-image-generator-olive-tau.vercel.app/",
    
  },
  {
    id: 4,
    image: IMG4,
    title: "React Portfolio Site for a Software Developer",
    demo: "https://godfreyy.netlify.app/",
    
  },
  {
    id: 3,
    image: IMG3,
    title: "JavaScript | HTML | CSS Calculator",
    demo: "https://fabulous-otter-35c137.netlify.app/",
  },
  
];

const Project = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Projects</h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo, brief }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              {/* <div className="portfolio__item-cta">
                <a href={github} className="btn">
                  Github
                </a>
                <a href={brief} className="btn">
                  Project Brief
                </a>
              </div> */}
              <div className="portfolio__item-cta">
                <a href={demo} className="btn btn-primary">
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Project;
